<template>
    <!-- 用户管理 —— 新增、编辑用户 -->
    <div class="box-card">
      <!-- 主要内容 -->
      <div>
        <div class="titlediv">
          <span>用户信息</span>
        </div>
        <el-divider></el-divider>
        <!-- <div class="bottomline"></div> -->
        <el-form :model="queryForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="用户名：" prop="user_name">
            <el-input maxlength="15" :value="queryForm.user_name" placeholder="请输入用户名" @input="(e) => (queryForm.user_name = validSpace(e))"></el-input>
            <span style="margin-left:15px;color:#999;">说明：默认密码“88888888”</span>
          </el-form-item>
          <el-form-item v-if="isedit && isAdmin" label="密码：" prop="password">
            <el-input show-password maxlength="15" :value="queryForm.password" @input="(e) => (queryForm.password = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="角色权限：" prop="role_id" v-if="!isAdmin">
            <el-select popper-class="my-select" v-model="queryForm.role_id" placeholder="请选择角色权限">
              <el-option v-for="item in role_list" :key="item._id" :label="item.role_name" :value="item._id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="isAdmin" label="集团：" prop="group_id">
            <el-select popper-class="my-select" v-if="!isedit" :disabled="isedit" v-model="queryForm.group_id" placeholder="请选择集团">
              <el-option v-for="item in groupList" :key="item._id" :label="item.group_name" :value="item._id">
              </el-option>
            </el-select>

            <el-input v-if="isedit" maxlength="30" disabled :value="queryForm.group_name"></el-input>
          </el-form-item>

          <el-form-item v-if="isAdmin" label="有效期至：" prop="valid_period_to">
            <el-date-picker ref="DataPicker" v-if="!midllejian" v-model="queryForm.valid_period_to" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="请选择日期" :editable="false" :picker-options="pickerOptions">
            </el-date-picker>
            <el-input v-if="midllejian" maxlength="30" value="永久" @focus="getFocus">
              <span class="el-input__prefix cye-lm-tag"><i class="el-input__icon el-icon-date"></i></span>
            </el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input maxlength="30" placeholder="请输入备注信息" :value="queryForm.remarks" @input="(e) => (queryForm.remarks = validSpace(e))"></el-input>
          </el-form-item>
        </el-form>
        <el-tree v-if="!isAdmin" class="tree-style" :data="treeList" show-checkbox node-key="id" :props="defaultProps" ref="projectTree" default-expand-all :expand-on-click-node="false" :default-checked-keys="checkTreeList">
          <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{node.label}}</span>
            <span style="color:red;">{{data.id}}</span>
          </span> -->
        </el-tree>
      </div>
      <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitForm" v-preventReClick>保 存</lbButton>
    </div>
  </template>

<script>
import axios from 'axios'
import request from '@/api/systemapi'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
export default {
  data () {
    // 自定义验证规则  用户密码
    var psdRule = (rule, value, callback) => {
      const psdReg = /^[\w]{8,14}$/
      if (psdReg.test(value)) {
        return callback()
      }
      callback(new Error())
    }
    const checkUser = (rule, value, callback) => {
      this.userNameIsReuse(callback)
    }
    const that = this
    return {
      title: '',
      queryForm: {
        _id: '',
        user_name: '',
        password: '88888888',
        role_id: '',
        group_id: '', // 集团id
        valid_period_to: '3000-01-01 00:00:00', // 有效期
        remarks: '', // 备注
        role_name: '',
        group_name: ''
      },
      rules: {
        user_name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          // {
          //   min: 3,
          //   max: 10,
          //   message: "长度在 3 到 10 个字符",
          //   trigger: "blur",
          // },
          { validator: checkUser, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            validator: psdRule,
            message: ' 8~14个字符，包含字母、数字，下划线，区分大小写',
            trigger: 'blur'
          }
        ],
        role_id: [
          { required: true, message: '请选择角色权限', trigger: 'change' }
        ],
        group_id: [
          { required: true, message: '请选择集团', trigger: 'change' }
        ],
        valid_period_to: [
          { required: true, message: '请选择有效期', trigger: 'change' }
        ]
      },
      isedit: false,
      // 表格数据
      tableData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      pickerOptions: {
        disabledDate (time) {
          //  如果没有后面的-8.64e6就是不可以选择今天的
          return time.getTime() < Date.now() - 8.64e7
        },
        shortcuts: [
          {
            text: '永久',
            onClick (picker) {
              // that.queryForm.valid_period_to = "永久";
              const date = '3000-01-01 00:00:00'
              picker.$emit('pick', date)
              that.midllejian = true
            }
          }
        ]
      },
      midllejian: true,
      treeList: [], // 权限列表
      checkTreeList: [] // 选中的数据
    }
  },
  created () { },
  computed: {
    ...mapGetters(['isAdmin', 'getUserInfo']),
    ...mapState(['role_list', 'groupList'])
  },
  mounted () {
    const storaItem = JSON.parse(sessionStorage.getItem('userEdit'))
    console.log('storaItem----', storaItem)
    if (storaItem) {
      const { row, isedit } = storaItem
      if (isedit && row) {
        this.isedit = true
        const {
          _id = '',
          user_name = '',
          password = '',
          valid_period_to = '',
          role_id = '',
          group_id = '',
          remarks = '',
          role_name = '',
          group_name = ''
        } = row
        this.queryForm = {
          _id: _id,
          user_name: user_name,
          password: password,
          role_id: role_id,
          group_id: group_id, // 集团id
          valid_period_to: this.$moment(valid_period_to).format('YYYY-MM-DD HH:mm:ss'), // 有效期
          remarks: remarks, // 备注
          role_name: role_name,
          group_name: group_name
        }
        // 判断有无此角色权限
        this.findRoleList(role_id)
        this.title = '编辑用户'
        if (valid_period_to && this.isLongTime(valid_period_to)) {
          this.midllejian = true
        } else {
          this.midllejian = false
        }
        if (this.isAdmin) {
          this.getGropList()
        } else {
          this.fnAllSelectList({ user_id: this.queryForm._id })
        }
      }
    } else {
      this.title = '新增用户'
      if (this.isAdmin) {
        this.getGropList()
      } else {
        this.fnAllSelectList()
      }
    }

    this.requestRoleList()
  },
  methods: {
    ...mapActions(['requestRoleList', 'getGropList']),
    ...mapMutations(['setQueryStatus']),
    getFocus () {
      this.queryForm.valid_period_to = ''
      this.midllejian = false
      const that = this
      setTimeout(() => {
        that.$refs.DataPicker.focus()
      }, 200)
    },
    // 获取集团管理员新增普通用户的权限
    fnAllSelectList (row) {
      var that = this
      axios.all([this.fnGetGroupScreenListByUserId(row), this.fnGetParkingListByUserId(row), this.fnGetAssetProjectListByUserId(row), this.fnGetMonitorSceneListByUserId(row), this.fnGetOrganizationListByUserId(row)]).then(
        axios.spread(function (screen, parking, asset, monitor, organi) {
          var treeList = []
          if (screen && screen.Code === 200 && screen.Data) treeList = treeList.concat(screen.Data)
          console.log('停车场权限---', parking.Data)
          if (parking && parking.Code === 200 && parking.Data) treeList = treeList.concat(parking.Data)
          if (asset && asset.Code === 200 && asset.Data) treeList = treeList.concat(asset.Data)
          console.log('监控场景权限----', monitor)
          if (monitor && monitor.Code === 200 && monitor.Data) treeList = treeList.concat(monitor.Data)
          console.log('组织权限----', organi)
          if (organi && organi.Code === 200 && organi.Data) treeList = treeList.concat(organi.Data)

          that.treeList = that.setTreeProjectType(treeList)
          // console.log(that.treeList);
          const newArr = []
          that.loopAdd(that.treeList, newArr)
          that.checkTreeList = newArr
        }))
    },
    // 设置项目类型
    setTreeProjectType (treeList) {
      // 路内停车场 = 1,  路外停车场 = 2,  充电桩 = 3,  资产 = 4,  监控 = 5,  告警 = 6,  行政 = 7,  系统 = 8,  运维中心 = 9,  其他 = 10,  商户优惠 = 11,  移动智慧小区 = 12
      const childNode = (data, type) => {
        data.forEach(item => {
          item.project_type = type
          if (item.children && item.children.length > 0) {
            childNode(item.children, type)
          }
        })
        return data
      }
      treeList.forEach(item => {
        childNode(item.children, item.id)
      })
      return treeList
    },
    // 集团管理员新增普通用户时获取停车场权限
    async fnGetParkingListByUserId (row) {
      return await request.getParkingListByUserId(row)
    },
    // 普通管理员新增、编辑用户时获取资产权限
    async fnGetAssetProjectListByUserId (row) {
      return await request.getAssetProjectListByUserId(row)
    },
    // 普通管理员新增、编辑用户时获取监控场景权限
    async fnGetMonitorSceneListByUserId (row) {
      return await request.getMonitorSceneListByUserId(row)
    },
    // 普通管理员新增、编辑用户时获取大屏权限
    async fnGetGroupScreenListByUserId (row) {
      return await request.getGroupScreenListByUserId(row)
    },
    // 普通管理员新增、编辑用户时获取组织权限
    async fnGetOrganizationListByUserId (row) {
      return await request.getOrganizationListByUserId(row)
    },
    // 返回
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // submitForm('ruleForm')提交
    submitForm () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {}
          params.user_name = this.queryForm.user_name
          params.role_id = this.queryForm.role_id
          params.remarks = this.queryForm.remarks
          if (this.isAdmin) {
            params.group_id = this.queryForm.group_id
            params.valid_period_to = this.queryForm.valid_period_to
          } else {
            // project_type（项目类型）：
            // 路内停车场 = 1,  路外停车场 = 2,  充电桩 = 3,  资产 = 4,  监控 = 5,  告警 = 6,  行政 = 7,  系统 = 8,  运维中心 = 9,  其他 = 10,  商户优惠 = 11,  移动智慧小区 = 12
            var user_project_list = []
            var halfCheckNode = this.$refs.projectTree.getHalfCheckedNodes() // 获取半选中节点
            var checkNode = this.$refs.projectTree.getCheckedNodes().concat(halfCheckNode) // 获取选中节点
            checkNode.forEach(item => {
              if (typeof item.id !== 'number') {
                user_project_list.push({
                  project_id: item.id,
                  project_type: item.project_type
                })
              }
            })
            console.log('user_project_list-----', user_project_list)
            const screenIdList = []
            const filterUserProject = []
            user_project_list.forEach(item => {
              if (item.project_type === 13) {
                screenIdList.push(item.project_id)
              } else {
                filterUserProject.push(item)
              }
            })
            params.user_project_list = filterUserProject
            params.screen_id_list = screenIdList
          }
          if (this.isedit) {
            if (this.isAdmin) {
              params.password = this.queryForm.password
            }
            params._id = this.queryForm._id
            this.updateUser(params)
          } else {
            this.addNewUser(params)
          }
        } else {
          return false
        }
      })
    },
    // resetForm('ruleForm')重置
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    async addNewUser (params) {
      const res = await request.addNewUser(params)
      if (res && res.Code === 200) {
        this.$msg.success('操作成功')
        this.$emit('closeDialog', false)
      }
    },
    async updateUser (params) {
      const res = await request.updateUser(params)
      if (res && res.Code === 200) {
        this.$msg.success('更新成功')
        // this.$router.go(-1);
        this.defaultForm()
      }
    },
    async userNameIsReuse (callback) {
      const params = {}
      params.user_name = this.queryForm.user_name
      if (this.isedit) {
        params.user_id = this.queryForm._id
      }
      const res = await request.userNameIsReuse(params)
      if (res.Code !== 200) {
        callback(new Error('用户名重复'))
      } else {
        callback()
      }
    },
    loopAdd (oldArray, newArray) {
      for (const item of oldArray) {
        if (
          (item.children !== null &&
              typeof item.children !== 'undefined' &&
              item.children.length === 0) ||
            item.children === null ||
            typeof item.children === 'undefined'
        ) {
          if (item.flag) {
            newArray.push(item.id)
          }
        } else {
          this.loopAdd(item.children, newArray)
        }
      }
    },
    findRoleList (rowId) {
      const filterResult = this.role_list.findIndex(item => {
        return item._id === rowId
      })
      if (filterResult === -1) {
        this.queryForm.role_id = ''
      }
    }
  },
  beforeDestroy: function () {
    sessionStorage.removeItem('userEdit')
  }
}
</script>

  <style scoped lang='less'>
  .el-select {
    width: 70%;
  }
  .el-input {
    width: 70%;
  }
  // .el-input__inner {
  //   height: 36px;
  //   line-height: 36px;
  // }
  .button_box {
    display: flex;
  }
  .button_box .el-button {
    margin-left: 15px;
    width: auto;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-button {
    width: auto !important;
  }

  .titlediv {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 18px;
    color: black;
    margin: 5px;
    font-weight: 700;
    width: "100%";
  }
  .bottomline {
    position: relative;
    left: 0;
    right: 0;
    height: 1px;
    width: "100%";
    background-color: #d4d4d4;
    margin-bottom: 20px;
  }
  .el-tree {
    /deep/ .el-tree-node__children {
      display: flex;
      flex-wrap: wrap;
      .el-tree-node {
        width: 250px;
      }
      .el-tree-node__children {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .box-card {
  position: relative;
  padding-bottom: 30px;
  .demo-ruleForm{
    overflow: auto;
  }

  .checkButton {
    position: absolute;
    right: 10px;
  }

  .checkButton2 {
    position: absolute;
    right: 110px;
  }
  .tree-style{
    max-height: 410px;
    overflow-y: auto;
  }
}
  </style>
